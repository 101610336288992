<template>
  <div>
    <div>
      <div>
        <h2 class="titlePage_subtitle titlePage">Flyscreen Port Sets</h2>
        <Form v-slot="{ errors }" action="" class="form" >
          <div class="bordered">
            <div class="portSet">
              <div class="portSet__item portSet__item_title">
                <span class="form__labelTitle"></span>
                <span class="form__labelTitle">Width</span>
                <span class="form__labelTitle" :style="{order:checkOrder}">Drop</span>
                <span class="form__labelTitle">Rotation</span>
              </div>

              <div class="portSet__item"
                   v-for="(item, index) in this.settings.choices"
                   :key="index"
              >
                <label class="form__label">
                  <input type="checkbox" hidden class="form__checkbox"
                         @change="triggerDisabled(item)"
                         :checked="item.selected"
                  >
                  <span class="form__checkbox_icon"></span>
                </label>

                <label class="form__label">
                  <Field type="text" class="form__input"
                         name="width"
                         v-model="item.width"
                         :disabled="isWidthAndDropDisabled(item)"
                         :rules="{
                           required: true,
                           min_value:validateMinWidth(item),
                           max_value:validateMaxWidth(item)
                         }"
                  />
                </label>

                <label class="form__label" :style="{order:checkOrder}">
                  <Field type="text" class="form__input"
                         name="drop"
                         v-model="item.height"
                         :disabled="isWidthAndDropDisabled(item)"
                         :rules="{
                           required: true,
                           min_value:validateMinDrop(item),
                           max_value:validateMaxDrop(item)
                         }"
                  />
                </label>

                <label class="form__label">
                  <Field as="select" class="form__input"
                          name="rotation"
                          :disabled="!item.selected"
                          :rules="'required'"
                          v-model="item.rotation"
                  >
                    <option value="" selected disabled hidden>Select Rotation</option>
                    <option v-for="angle in rotation"
                            :key="angle"
                            :value="angle"
                    >{{angle}}
                    </option>
                  </Field>
                </label>

              </div>

            </div>
          </div>

          <ul>
            <li v-for="group in errors">
              <ul>
                <li v-for="(error, index) in group"
                    v-if="index === 0 || index === 1"
                    class="form__error"
                >{{ error }}
                </li>
              </ul>
            </li>
          </ul>

          <p class="text_center" :class="{'text_red': view_inside}">Please note: Looking from {{view_inside ? 'inside' : 'outside'}}</p>
          <p class="text_center">Position is measured from bottom left of the screen to centre of Flyscreen Port Set</p>

        </Form>
        <template v-if="actionLinksPortalTarget">
          <portal :to="actionLinksPortalTarget">
            <button type="submit" @click="save" class="btn btn_transparent"><svg-icon name="solid/check" /> Done</button>
          </portal>
        </template>
        <template v-else>
          <button type="submit" @click="save" class="btn btn_default"><svg-icon name="solid/check" /> Done</button>
        </template>
      </div>
    </div>
    <transition name="fade" mode="out-in">
      <select-colors v-show="selectColours"
                     @onSelect="setColour"></select-colors>
    </transition>

  </div>
</template>

<script>
  import {mapState, mapGetters} from 'vuex';
  import options from "../../../store/modules/order/options";
  import {defineAsyncComponent} from "vue";
  const SelectColors = defineAsyncComponent(() => import('../../modals/OptionColours'));

  export default {
    name: "FlyscreenPortSets",

    data() {
      return {

        selected: {
            components: {},
            limits: {},
            choices: {}
        }  ,

        m: {},
        prefer_entry: '',
        view_inside: false,
        rotation: ['0', '90', '180', '270'],
        width: '',
        drop: ''
      }
    },

      components: {
          SelectColors,
      },

    props: ['options', 'checkIsSetPackage', 'actionLinksPortalTarget'],

    methods: {
      closePopup() {
        this.$store.commit('options/closeAvailable');
      },

      triggerDisabled(obj) {
        obj.selected = !obj.selected;
        obj.width = '';
        obj.height = '';
        obj.rotation = '';
        this.$store.commit('orderPosition/setOption', { option: 'PRTS', selection: this.settings});

      },

      save() {
      /*  this.$validator.validate().then(valid => {
          if (valid) {*/
              this.$store.commit('orderPosition/setOption', { option: 'PRTS', selection: this.settings});
              this.$store.commit('orderPosition/optionNext', 'PRTS');
        /*  }
        })*/
      },
      isWidthAndDropDisabled(item) {
        return !item.selected;
      },
      validateMaxDrop(item) {
        return parseFloat(this.order.htl - this.settings.limits.height).toFixed(0);
      },
      validateMinDrop(item) {
        return parseFloat(this.settings.limits.height).toFixed(0);
      },
      validateMinWidth(item) {
        return parseFloat(this.settings.limits.width).toFixed(0);
      },
      validateMaxWidth(item) {
        return parseFloat(this.order.wdt - this.settings.limits.width).toFixed(0);
      },

        openColourSelection(){
            if (this.settings.colour_group)
                this.$store.commit('optionColours/colourGroup', this.settings.colour_group);

            this.$store.commit('optionColours/openModal', true);
            this.$store.commit('optionColours/getColors');
        },

        setColour(){
            let c = this.$store.getters['optionColours/selectedColour'];
            this.settings.colour = c;

            if (this.settings.colour) {
                this.$store.dispatch('options/updateColour', {option: 'PRTS', colour: c} );
            }
        },

    },

    computed: {
      checkOrder() {
        if (this.prefer_entry === 'drop') {
          return 1
        }
        else return 2;
      },
      ...mapState('orderPosition', ['orderPosition']),
      ...mapState('options', ['name', 'price', 'modalView']),
        ...mapGetters({
            order: 'orderPosition/orderPosition',
            selectColours: 'optionColours/openModal',
        }),

        settings() {
            return this.$store.getters['orderPosition/getCurrentOptionSettings'];
        },

    },

  }
</script>

<style scoped lang="scss">
  @import '../../../../src/assets/scss/utils/vars';

  .form {

    &__input {
      border-color: $navy;
    }

    .bordered {
      border: 1px solid $navy;
      padding: 10px 0;
      margin: 20px 0;
    }

    &__labelTitle {
      color: $navy
    }

    .justify-content-center, .justify-content-between {
      display: flex;
      margin: 10px;
    }

    .btn {
      margin: 20px 0 0;
      max-width: none;
      width: 100%;
    }

  }

  .modal__close {
    color: $green;
  }
</style>
